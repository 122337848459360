import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { SplashScreen } from "src/components/splash-screen";
import { WhiteLabelConfig } from "src/types/snugtotal";

const axios = require("axios").default;

interface WhiteLabelConfigProviderProps {
  children: ReactNode;
}

type WhiteLabelConfigStoreAction = {
  type: "SET_WHITE_LABEL_CONFIG";
  payload: WhiteLabelConfig;
};

const whiteLabelConfigReducer = (
  state: WhiteLabelConfig,
  action: WhiteLabelConfigStoreAction
): WhiteLabelConfig => {
  switch (action.type) {
    case "SET_WHITE_LABEL_CONFIG":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const WhiteLabelConfigContext = createContext<WhiteLabelConfig>({
  domain: "",
  disclaimer: null,
  professional_group: null,
  block_signup: false,
  product_name: "",
});

export const WhiteLabelConfigProvider: FC<WhiteLabelConfigProviderProps> = (
  props
) => {
  const { children } = props;
  const [state, dispatch] = useReducer(whiteLabelConfigReducer, {
    domain: "",
    disclaimer: null,
    professional_group: null,
    block_signup: false,
    product_name: "",
  });

  useEffect(() => {
    const getWhiteLabelConfig = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_SNUG_API_URL + "/v3/white-label-config/"
        );
        dispatch({
          type: "SET_WHITE_LABEL_CONFIG",
          payload: response.data.data,
        });
      } catch (error) {
        console.error(error);
      }
    };
    getWhiteLabelConfig();
  }, []);

  if (!state.domain) {
    return <SplashScreen />;
  }

  return (
    <WhiteLabelConfigContext.Provider
      value={{
        domain: state.domain,
        disclaimer: "This is a sample disclaimer.",
        professional_group: state?.professional_group || null,
        block_signup: state?.block_signup,
        product_name: state.product_name,
      }}
    >
      {children}
    </WhiteLabelConfigContext.Provider>
  );
};

export default function useWhiteLabelConfig() {
  return useContext(WhiteLabelConfigContext);
}
