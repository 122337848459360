import type { FC, ReactNode } from "react";
import { createContext, useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { authApi } from "src/api/auth";

import { Issuer } from "src/utils/auth";
import { emailVerify } from "src/api/snugtotal/auth";
import { User } from "src/types/user";
import { useLocation } from "react-router";
import { UserDataObj } from "src/types/snugtotal";
import axios from "axios";
import { GenericPath } from "src/api/snugtotal/estate_app";

const STORAGE_KEY = "s_at";
const REFRESH_STORAGE_KEY = "s_rt";

type VerifyEmailRequest = {
  token: string;
  password: string | null;
  first_name: string | null;
};

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isEmailVerified: boolean;
  user: User | null;
}

enum ActionType {
  INITIALIZE = "INITIALIZE",
  EMAIL_VERIFY = "EMAIL_VERIFY",
  SIGN_IN = "SIGN_IN",
  REFRESH_TOKEN = "REFRESH_TOKEN",
  SIGN_UP = "SIGN_UP",
  SIGN_OUT = "SIGN_OUT",
  ADD_STRIPE_ID = "ADD_STRIPE_ID",
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    isEmailVerified: boolean;
    user: User | null;
    lastRefresh: number;
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
  payload: {
    user: User | null;
  };
};

type RefreshAction = {
  type: ActionType.REFRESH_TOKEN;
  payload: {
    user: User | null;
  };
};

type SignUpAction = {
  type: ActionType.SIGN_UP;
  payload: {
    user: User | null;
  };
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type VerifyAction = {
  type: ActionType.EMAIL_VERIFY;
  payload: {
    user: User;
  };
};
type AddStripeId = {
  type: ActionType.ADD_STRIPE_ID;
  payload: {
    user: User;
  };
};

type Action =
  | InitializeAction
  | SignInAction
  | RefreshAction
  | SignUpAction
  | SignOutAction
  | VerifyAction
  | AddStripeId;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  isEmailVerified: false,
  user: null,
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isEmailVerified: user?.email_verification?.email_verified || false,
      user,
    };
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isEmailVerified: user?.email_verification?.email_verified || false,
      user,
    };
  },
  REFRESH_TOKEN: (state: State, action: RefreshAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isEmailVerified: user?.email_verification?.email_verified || false,
      user,
    };
  },
  SIGN_UP: (state: State, action: SignUpAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isEmailVerified: user?.email_verification?.email_verified || false,
      user,
    };
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    isEmailVerified: false,
    user: null,
  }),
  EMAIL_VERIFY: (state: State, action: VerifyAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      isEmailVerified: true,
      isAuthenticated: true,
      user,
    };
  },
  ADD_STRIPE_ID: (state: State, action: AddStripeId): State => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export interface AuthContextType extends State {
  issuer: Issuer.JWT;
  signIn: (email: string, password: string) => Promise<void>;
  refreshToken: () => Promise<void>;
  verifyEmail: (request: VerifyEmailRequest) => Promise<void>;
  signUp: (
    email: string,
    password: string,
    name: string,
    proCode: string | null,
    is_marble: boolean
  ) => Promise<boolean>;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  refreshToken: () => Promise.resolve(),
  verifyEmail: () => Promise.resolve(),
  signUp: () => Promise.resolve(false),
  signOut: () => Promise.resolve(),
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();

  const initialize = useCallback(async (): Promise<void> => {
    try {
      const queryParams = new URLSearchParams(location.search);
      let accessToken = null;
      accessToken = queryParams.get("at");
      if (accessToken) {
        localStorage.setItem("s_at", accessToken);
        queryParams.delete("at");
      }
      let authorization_context = null;
      authorization_context = queryParams.get("ac");
      if (authorization_context) {
        sessionStorage.setItem("s_ac", authorization_context);
        queryParams.delete("ac");
      }

      accessToken = window.localStorage.getItem(STORAGE_KEY);
      if (accessToken) {
        const user = await authApi.me({ accessToken });
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            isEmailVerified: user?.email_verification?.email_verified ?? false,
            user,
            lastRefresh: Date.now(), // Update the lastRefreshed property
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            isEmailVerified: false,
            user: null,
            lastRefresh: Date.now(), // Update the lastRefreshed property
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          isEmailVerified: false,
          user: null,
          lastRefresh: Date.now(), // Update the lastRefreshed property
        },
      });
    }
  }, [dispatch, location.search]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = useCallback(
    async (email: string, password: string): Promise<void> => {
      // clear auth tokens from local storage
      localStorage.removeItem("s_at");
      localStorage.removeItem("s_rt");

      const { accessToken, refreshToken } = await authApi.signIn({
        email: email.toLocaleLowerCase().trim(),
        password,
      });
      localStorage.setItem(STORAGE_KEY, accessToken);
      localStorage.setItem(REFRESH_STORAGE_KEY, refreshToken);
      const user = await authApi.me({ accessToken });
      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const refreshToken = useCallback(async (): Promise<void> => {
    const refreshToken = window.localStorage.getItem(REFRESH_STORAGE_KEY);
    try {
      if (!refreshToken) {
        throw new Error("No access token found");
      }
      const { accessToken: newAccessToken } = await authApi.refresh({
        refresh: refreshToken,
      });
      localStorage.setItem(STORAGE_KEY, newAccessToken);
      localStorage.setItem(REFRESH_STORAGE_KEY, refreshToken);
      const user = await authApi.me({ accessToken: newAccessToken });
      dispatch({
        type: ActionType.REFRESH_TOKEN,
        payload: {
          user,
        },
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.SIGN_OUT,
      });
    }
  }, [dispatch]);

  const verifyEmail = useCallback(
    async (request: VerifyEmailRequest): Promise<void> => {
      const { token, password, first_name } = request;

      // clear auth tokens from local storage
      localStorage.removeItem("s_at");
      localStorage.removeItem("s_rt");

      const res = await emailVerify(token, password, first_name);
      const user = await authApi.me({ accessToken: res.data.access });
      localStorage.setItem(STORAGE_KEY, res.data.access);
      dispatch({
        type: ActionType.EMAIL_VERIFY,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const signUp = useCallback(
    async (
      email: string,
      password: string,
      name: string,
      proCode: string | null,
      is_marble: boolean
    ): Promise<boolean> => {
      const handleUserDataOnSignup = async (
        email: string,
        name: string,
        accessToken: string,
        proCode: string | null,
        is_marble: boolean
      ) => {
        // This is a kludge to get the user data object created on signup.
        const instance = axios.create({
          baseURL: process.env.REACT_APP_SNUG_API_URL,
        });
        try {
          const uD: UserDataObj = {
            ud_id: null, // This is null because we are creating a new user data
            full_name: name,
            contact_email: email.toLocaleLowerCase().trim(),
            referral_pro_ud_id: proCode || null,
            assigned_total_domain: window.location.host.replace(
              "https://",
              process.env.REACT_APP_DEFAULT_SNUG_DOMAIN || ""
            ),
          };
          if (is_marble) {
            uD.is_marble = true;
          }
          await instance.post(GenericPath.USER_DATA_V3, uD, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              // add X-User-Domain header to the request as the full domain the user is on currently (subdomain included) but not the https://
              "X-User-Domain": window.location.host.replace("https://", ""),
            },
          });
        } catch (error) {
          console.error(
            "There was an error adding your userdata object. It will be generated on first login after email verification."
          );
        }
      };

      try {
        // clear auth tokens from local storage
        localStorage.removeItem("s_at");
        localStorage.removeItem("s_rt");

        const { user } = await authApi.signUp({
          email: email.toLocaleLowerCase().trim(),
          password,
          name: name.trim(),
        });
        const { accessToken } = await authApi.signIn({
          email: email.trim().toLocaleLowerCase(),
          password,
        });
        localStorage.setItem(STORAGE_KEY, accessToken);
        await handleUserDataOnSignup(
          email,
          name,
          accessToken,
          proCode,
          is_marble
        );
        dispatch({
          type: ActionType.SIGN_UP,
          payload: {
            user,
          },
        });
        return true; //this is PWBypass. a little janky there's probably a better way to do this.
      } catch (err) {
        // if error response code from axios api is 409 with response.data.code === '2', then set is authenticated to true
        if (err?.response?.status === 409 && err?.response?.data?.code === 2) {
          console.error(`User already exists, but not verified. ${err}`);
          // in this case user is already registered, so we set is authenticated to true
          // but the user does not have a verified email so we let them pass registration
          // and go to email verification.
          dispatch({
            type: ActionType.SIGN_UP,
            payload: {
              user: null,
            },
          });
        } else {
          throw err;
        }
        return false; //this is PWBypass. a little janky there's probably a better way to do this.
      }
    },
    [dispatch]
  );

  const signOut = useCallback(async (): Promise<void> => {
    localStorage.removeItem(STORAGE_KEY);
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        refreshToken,
        verifyEmail,
        signUp,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
