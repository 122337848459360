import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import toast from "react-hot-toast";
import type { File } from "src/components/file-dropzone";
import {
  GenericPath,
  useV3Create,
  useV3DeleteById,
  useV3DocumentDownload,
  useV3GenericGetData,
  useV3Update,
  useV3UploadDocument,
} from "src/api/snugtotal/estate_app";
import {
  allRoleTypesArray,
  authorizationRolesArray,
  downloadFileFromS3,
  estatePlanningRolesArray,
  rolesWithHierarchy,
} from "src/utils/snug";
import { useUserDataStore } from "src/hooks/snug/use-userData";
import { useMounted } from "src/hooks/use-mounted";
import {
  AuthorizationRoleTypes,
  FirstDegreePeopleRole,
  ProfessionalClientRolePeopleRole,
  TargetPeopleRole,
  TargetRoleUserData,
  UserDataObj,
} from "src/types/snugtotal";
import { slackHook } from "src/utils/snug-slack-hooks";
import sortBy from "lodash/sortBy";

// Use lodash to sort the people roles based on allRoleTypesArray
const sortPeopleByRoleUsingLodash = (
  people: FirstDegreePeopleRole[]
): FirstDegreePeopleRole[] => {
  // Create a map for quick lookup of index positions based on role
  const roleIndexMap = new Map(
    allRoleTypesArray.map((role, index) => [role, index])
  );

  return sortBy(people, [
    (person) => roleIndexMap.get(person.role || "") ?? Infinity,
  ]);
};

type PeopleRolesStoreAction =
  | { type: "SET_PEOPLE_ROLES"; payload: FirstDegreePeopleRole[] }
  | { type: "ADD_OR_UPDATE_PEOPLE_ROLES"; payload: FirstDegreePeopleRole }
  | { type: "DELETE_PEOPLE_ROLES"; payload: string }
  | { type: "SET_PEOPLE_ROLES_LOADING"; payload: boolean }
  | { type: "SET_PEOPLE_ROLES_SAVING_NEW"; payload: boolean }
  | { type: "SET_TARGET_ROLES"; payload: TargetPeopleRole[] }
  | { type: "ADD_OR_UPDATE_TARGET_ROLES"; payload: TargetPeopleRole }
  | { type: "SET_TARGET_ROLES_LOADING"; payload: boolean }
  | { type: "SET_TARGET_ROLES_SAVING_NEW"; payload: boolean }
  | { type: "SET_PEOPLE_ROLES_FILES_TO_UPLOAD"; payload: File[] }
  | {
      type: "SET_CURRENT_VIEWING_USER_DATA";
      payload: UserDataObj | TargetRoleUserData | null;
    }
  | { type: "SET_LOADING_VIEWING_USER_DATA"; payload: boolean }
  | { type: "SET_ASSUMED_ACCOUNT_ROLE"; payload: AuthorizationRoleTypes | null }
  | { type: "SET_TARGET_USER_DATAS"; payload: UserDataObj[] }
  | { type: "SET_TARGET_USER_DATA_SAVING"; payload: boolean }
  | { type: "ADD_OR_UPDATE_TARGET_USER_DATA"; payload: UserDataObj }
  | { type: "SET_CLIENT_ROLE"; payload: ProfessionalClientRolePeopleRole }
  | { type: "SET_CLIENT_ROLE_LOADING"; payload: boolean };

// Define reducer function for usePeopleRolesStore
const peopleRolesStoreReducer = (
  state: UserDataContextStoreState,
  action: PeopleRolesStoreAction
): UserDataContextStoreState => {
  switch (action.type) {
    case "SET_PEOPLE_ROLES":
      const sortedPeopleRoles = sortPeopleByRoleUsingLodash(action.payload);
      return { ...state, peopleRoles: sortedPeopleRoles };
    case "ADD_OR_UPDATE_PEOPLE_ROLES":
      const peopleRoles = state.peopleRoles;
      const index = peopleRoles.findIndex((pr) => pr.id === action.payload.id);
      if (index > -1) {
        peopleRoles[index] = action.payload;
      } else {
        peopleRoles.push(action.payload);
      }
      return { ...state, peopleRoles };
    case "DELETE_PEOPLE_ROLES":
      return {
        ...state,
        peopleRoles: state.peopleRoles.filter((pr) => pr.id !== action.payload),
      };
    case "SET_PEOPLE_ROLES_LOADING":
      return { ...state, peopleRolesLoading: action.payload };
    case "SET_PEOPLE_ROLES_SAVING_NEW":
      return { ...state, peopleRolesSavingNew: action.payload };
    case "SET_TARGET_ROLES":
      return { ...state, targetAuthRoles: action.payload };
    case "ADD_OR_UPDATE_TARGET_ROLES":
      const targetAuthRoles = state.targetAuthRoles;
      const targetAuthRoleIndex = targetAuthRoles.findIndex(
        (pr) => pr.id === action.payload.id
      );
      if (targetAuthRoleIndex > -1) {
        targetAuthRoles[targetAuthRoleIndex] = action.payload;
      } else {
        targetAuthRoles.push(action.payload);
      }
      return { ...state, targetAuthRoles };
    case "SET_TARGET_ROLES_LOADING":
      return { ...state, targetAuthRolesLoading: action.payload };
    case "SET_TARGET_ROLES_SAVING_NEW":
      return { ...state, targetAuthRolesSavingNew: action.payload };
    case "SET_PEOPLE_ROLES_FILES_TO_UPLOAD":
      return { ...state, peopleRolesFilestoUpload: action.payload };
    case "SET_CURRENT_VIEWING_USER_DATA":
      return { ...state, currentViewingUserData: action.payload };
    case "SET_LOADING_VIEWING_USER_DATA":
      return { ...state, loadingViewingUserData: action.payload };
    case "SET_ASSUMED_ACCOUNT_ROLE":
      return { ...state, assumedAccountRole: action.payload };
    case "SET_TARGET_USER_DATA_SAVING":
      return { ...state, targetUserDataSaving: action.payload };
    case "SET_TARGET_USER_DATAS":
      return { ...state, targetUserDatas: action.payload };
    case "ADD_OR_UPDATE_TARGET_USER_DATA":
      const targetUserDatas = state.targetUserDatas;
      const targetUserDataIndex = targetUserDatas.findIndex(
        (ud) => ud.ud_id === action.payload.ud_id
      );
      if (targetUserDataIndex > -1) {
        targetUserDatas[targetUserDataIndex] = action.payload;
      } else {
        targetUserDatas.push(action.payload);
      }
      return { ...state, targetUserDatas };
    case "SET_CLIENT_ROLE":
      return { ...state, clientRole: action.payload };
    case "SET_CLIENT_ROLE_LOADING":
      return { ...state, clientRoleLoading: action.payload };
    default:
      return state;
  }
};

export interface UserDataContexType {
  ownerUserData: UserDataObj | null;
  userData: UserDataObj | null;
  targetUserDatas: UserDataObj[];
  userDataInitialLoading: boolean;
  userDataSaving: boolean;
  assumedAccountRole: AuthorizationRoleTypes | null;
  peopleRoles: FirstDegreePeopleRole[];
  peopleRolesLoading: boolean;
  peopleRolesSavingNew: boolean;
  peopleRolesFilestoUpload: File[];
  loadingViewingUserData: boolean;
  targetAuthRoles: TargetPeopleRole[];
  targetAuthRolesLoading: boolean;
  targetAuthRolesSavingNew: boolean;
  ownerUserDataInitialLoading: boolean;
  ownerUserDataSaving: boolean;
  clientRole: ProfessionalClientRolePeopleRole | null;
  clientRoleLoading: boolean;
  isPro: boolean;
  isWhiteLabel: boolean;
  handleRefreshPeopleRoles: () => void;
  handleInviteFromVerifyDialog: (targetUserData: UserDataObj | null) => void;
  handleAddOrEditOwnerUserData: (userData: UserDataObj) => void;
  handleAddOrUpdatePeopleRolesAndUserData: (
    ud_id: string | null,
    peopleRole: FirstDegreePeopleRole
  ) => Promise<FirstDegreePeopleRole | null>;
  handleAddOrUpdatePeopleRoleSingleCall: (
    ud_id: string | null,
    peopleRole: FirstDegreePeopleRole
  ) => Promise<void>;
  handleDeletePeopleRoles: (
    ud_id: string | null,
    id: string | null
  ) => Promise<void>;
  handleDeleteDocumentForPeopleRole: (
    peopleRoleId: string,
    document_id: string | null
  ) => Promise<void>;
  handleDownloadPeopleRolesDocument: (
    peopleRoleId: string,
    document_id: string | null
  ) => Promise<void>;
  handlePeopleRolesFileRemove: (file: File) => void;
  handlePeopleRolesFilesDrop: (newFiles: File[]) => void;
  handlePeopleRolesFilesRemoveAll: () => void;
  handleUploadPeopleRolesFiles: (
    ud_id: string | null,
    entityId: string
  ) => void;
  handleSetAuthorizationContext: (
    id: string,
    targetAuthRoles: TargetPeopleRole[]
  ) => void;
  handleGetTargetAuthRoles: (ud_id: string) => void;
  handleAddOrUpdateAuthorizationRoles: (
    peopleRole: FirstDegreePeopleRole,
    targetUserData: UserDataObj | null,
    advisorInvite: boolean
  ) => Promise<FirstDegreePeopleRole | null>;
  handleAddOrEditTargetUserData: (
    target_ud_id: string,
    userData: UserDataObj
  ) => Promise<UserDataObj | null>;
  handleSendShareEmail: (ud_id: string) => void;
  handleAcceptDomainLeadRequest: (
    entityId: string,
    lead_status: "ACCEPTED" | "NEW"
  ) => Promise<void> | void;
}

interface UserDataContextStoreState {
  peopleRoles: FirstDegreePeopleRole[];
  peopleRolesLoading: boolean;
  peopleRolesSavingNew: boolean;
  peopleRolesFilestoUpload: File[];
  targetAuthRoles: TargetPeopleRole[];
  targetAuthRolesLoading: boolean;
  targetAuthRolesSavingNew: boolean;
  currentViewingUserData: UserDataObj | null;
  targetUserDataSaving: boolean;
  targetUserDatas: UserDataObj[];
  assumedAccountRole: AuthorizationRoleTypes | null;
  loadingViewingUserData: boolean;
  clientRole: ProfessionalClientRolePeopleRole | null;
  clientRoleLoading: boolean;
}

export const UserDataContext = createContext<UserDataContexType>(
  {} as UserDataContexType
);

interface UserDataProviderProps {
  children: ReactNode;
}

// Possible refactore would be to handle api selection here
export const UserDataProvider: FC<UserDataProviderProps> = (props) => {
  const { children } = props;
  const isMounted = useMounted();
  const {
    userData,
    userDataInitialLoading,
    userDataSaving,
    handleAddOrEditOwnerUserData,
  } = useUserDataStore(true); //this is to get the signed-in user's data

  const [state, dispatch] = useReducer(peopleRolesStoreReducer, {
    peopleRoles: [],
    peopleRolesLoading: true,
    peopleRolesSavingNew: false,
    peopleRolesFilestoUpload: [],
    targetAuthRoles: [],
    targetAuthRolesLoading: true,
    targetAuthRolesSavingNew: false,
    currentViewingUserData: null,
    targetUserDataSaving: false,
    targetUserDatas: [],
    assumedAccountRole: null,
    loadingViewingUserData: false,
    clientRole: null,
    clientRoleLoading: true,
  });

  const deleteById = useV3DeleteById(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );
  const genericGetData = useV3GenericGetData<FirstDegreePeopleRole[]>(
    GenericPath.GENERIC_ENTITY_LIST_CREATE_V3
  );
  const createPeopleRole = useV3Create<FirstDegreePeopleRole>(
    GenericPath.GENERIC_ENTITY_LIST_CREATE_V3
  );
  const updatePeopleRole = useV3Update<FirstDegreePeopleRole>(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );
  const downloadDocument = useV3DocumentDownload(
    GenericPath.GENERIC_DOCUMENT_DOWNLOAD_V3
  );
  const uploadDocument = useV3UploadDocument<FirstDegreePeopleRole>(
    GenericPath.GENERIC_DOCUMENT_CREATE_V3
  );
  const deleteDocumentById = useV3DeleteById(
    GenericPath.GENERIC_DOCUMENT_DELETE_V3
  );
  const sendUserDataInviteFromVerify = useV3Create<UserDataObj>(
    GenericPath.INVITE
  );
  const sendUserDataShareFromVerify = useV3Create<UserDataObj>(
    GenericPath.SHARE
  );

  const createUserData = useV3Create<UserDataObj>(
    GenericPath.GENERIC_ENTITY_LIST_CREATE_V3
  );
  const updateUserData = useV3Update<UserDataObj>(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );
  const getTargetUserData = useV3GenericGetData<UserDataObj>(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );

  const getTargetRoles = useV3GenericGetData<TargetPeopleRole[]>(
    GenericPath.GENERIC_ENTITY_LIST_CREATE_V3
  );

  const getSingleTargetRole = useV3GenericGetData<TargetPeopleRole>(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );

  const acceptLeadRequest = useV3Update<ProfessionalClientRolePeopleRole>(
    GenericPath.GENERIC_ENTITY_RETRIEVE_UPDATE_DELETE_V3
  );

  const getProfessionalClientRole = useV3GenericGetData<
    ProfessionalClientRolePeopleRole[]
  >(GenericPath.GENERIC_ENTITY_LIST_CREATE_V3);

  const handleGetTargetAuthRoles = useCallback(
    async (ud_id: string) => {
      const params = {
        ud_id: ud_id,
        entity: "target-people-roles",
      };
      try {
        dispatch({ type: "SET_TARGET_ROLES_LOADING", payload: true });
        const response = await getTargetRoles(params, {
          role: ["AUTH_READONLY", "AUTH_COLLABORATOR"].join(","),
        });
        if (isMounted()) {
          dispatch({ type: "SET_TARGET_ROLES", payload: response });
        }
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted()) {
          dispatch({ type: "SET_TARGET_ROLES_LOADING", payload: false });
        }
      }
    },
    [getTargetRoles, isMounted]
  );

  const handleGetClientRole = useCallback(
    async (ud_id: string) => {
      const params = {
        ud_id: ud_id,
        entity: "client-roles",
      };
      try {
        dispatch({ type: "SET_CLIENT_ROLE_LOADING", payload: true });
        const response = await getProfessionalClientRole(params, {
          role: ["FINANCIAL_PROFESSIONAL_CLIENT"].join(","),
        });
        if (isMounted() && response.length > 0) {
          dispatch({ type: "SET_CLIENT_ROLE", payload: response[0] });
        }
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted()) {
          dispatch({ type: "SET_CLIENT_ROLE_LOADING", payload: false });
        }
      }
    },
    [isMounted, getProfessionalClientRole]
  );

  const handleAcceptDomainLeadRequest = useCallback(
    async (entityId: string, lead_status: "ACCEPTED" | "NEW") => {
      if (!userData?.ud_id) {
        console.error("No user data id provided");
        return;
      }
      const params = {
        ud_id: userData.ud_id,
        entity_id: entityId,
        entity: "client-roles",
      };
      try {
        dispatch({ type: "SET_CLIENT_ROLE_LOADING", payload: true });
        const response = await acceptLeadRequest(
          params,
          {
            lead_status: lead_status,
          },
          true
        );
        if (isMounted() && response) {
          dispatch({ type: "SET_CLIENT_ROLE", payload: response });
        }
      } catch (err) {
        toast.error(
          "There was an error handling the client request. Please try again."
        );
        console.error(err);
      } finally {
        if (isMounted()) {
          dispatch({ type: "SET_CLIENT_ROLE_LOADING", payload: false });
        }
      }
    },
    [isMounted, acceptLeadRequest, userData.ud_id]
  );

  const handleAddOrEditTargetUserData = useCallback(
    async (
      target_ud_id: string,
      userData: UserDataObj
    ): Promise<UserDataObj | null> => {
      if (!target_ud_id) {
        console.error("No user data id provided");
        return Promise.reject("No user data id provided");
      }

      try {
        dispatch({ type: "SET_TARGET_USER_DATA_SAVING", payload: true });
        const params = { ud_id: target_ud_id, entity: "role-target-user-data" };
        if (userData.ud_id) {
          const res = await updateUserData(
            { ...params, entity_id: userData.ud_id },
            userData
          );
          dispatch({ type: "ADD_OR_UPDATE_TARGET_USER_DATA", payload: res });
          return res;
        } else {
          const res = await createUserData(params, userData);
          dispatch({ type: "ADD_OR_UPDATE_TARGET_USER_DATA", payload: res });
          return res;
        }
      } catch (err) {
        console.error(err);
        throw err;
      } finally {
        dispatch({ type: "SET_TARGET_USER_DATA_SAVING", payload: false });
      }
    },
    [createUserData, updateUserData]
  );

  // NEEDS TO BE REVISITED ON V3. and changes so that the ROLE OBJECT is passed through?
  const handleSetAuthorizationContext = useCallback(
    async (
      authorizationPeopleRoleId: string | null,
      targetAuthRoles: TargetPeopleRole[]
    ) => {
      try {
        // if ud_id is equal to userData.ud_id then set assumedAccountRole to null
        if (!authorizationPeopleRoleId) {
          sessionStorage.removeItem("s_ac"); //this is to remove the assumed account role from the session storage
          dispatch({ type: "SET_CURRENT_VIEWING_USER_DATA", payload: null }); //should probably take this double save out eh.
          dispatch({ type: "SET_ASSUMED_ACCOUNT_ROLE", payload: null });
          return;
        }
        sessionStorage.setItem("s_ac", authorizationPeopleRoleId); //this is to set the assumed account role in the session storage so that on refresh the assumed account role is still there


        // get the user data object from the people role id and set it as entity_id
        let peopleRole = targetAuthRoles.find(
          (pr) => pr.id === authorizationPeopleRoleId
        );
        if (!peopleRole) {
          // retrieve the role from db if not found in state
          try {
            const params = {
              ud_id: userData?.ud_id || "",
              entity: "target-people-roles",
              entity_id: authorizationPeopleRoleId,
            };
            peopleRole = await getSingleTargetRole(params);
            dispatch({
              type: "ADD_OR_UPDATE_TARGET_ROLES",
              payload: peopleRole,
            });
          } catch (err) {
            throw new Error("People role not found");
          }
        }

        dispatch({ type: "SET_LOADING_VIEWING_USER_DATA", payload: true });

        const response = await getTargetUserData({
          ud_id: userData?.ud_id || "",
          entity: "role-target-user-data",
          entity_id: peopleRole?.user_data?.ud_id || "",
        });

        if (isMounted()) {
          dispatch({
            type: "SET_CURRENT_VIEWING_USER_DATA",
            payload: response,
          });
          dispatch({
            type: "SET_ASSUMED_ACCOUNT_ROLE",
            payload: peopleRole.role as AuthorizationRoleTypes,
          });
        }
      } catch (err) {
        console.error("ERROR - ", err);
      } finally {
        if (isMounted()) {
          dispatch({ type: "SET_LOADING_VIEWING_USER_DATA", payload: false });
        }
      }
    },
    [isMounted, getTargetUserData, getSingleTargetRole, userData?.ud_id]
  );

  const handleAddOrEditRolesTargetUserData = useCallback(
    async (
      ud_id: string,
      targetUserData: UserDataObj | null
    ): Promise<UserDataObj | null> => {
      try {
        if (!targetUserData) {
          throw new Error("No target user data found");
        }

        if (targetUserData?.last_login) {
          //this is here so that UserData's attached to account are not updated.
          return Promise.resolve(targetUserData);
        }

        dispatch({ type: "SET_TARGET_USER_DATA_SAVING", payload: true });
        const params = { ud_id: ud_id, entity: "role-target-user-data" };
        if (targetUserData.ud_id) {
          const res = await updateUserData(
            { ...params, entity_id: targetUserData.ud_id },
            targetUserData
          );
          dispatch({ type: "ADD_OR_UPDATE_TARGET_USER_DATA", payload: res });
          return res;
        } else {
          const res = await createUserData(params, targetUserData);
          dispatch({ type: "ADD_OR_UPDATE_TARGET_USER_DATA", payload: res });
          return res;
        }
      } catch (err) {
        console.error(err);
        return null;
        // throw err; Do not throw error. Just fail gracefully.
      } finally {
        dispatch({ type: "SET_TARGET_USER_DATA_SAVING", payload: false });
      }
    },
    [createUserData, updateUserData]
  );

  const handleDeleteTargetUserData = useCallback(
    (targetUserDataUdId: string) => {
      // get user data by id
      const targetUserData = state.targetUserDatas.find(
        (ud) => ud.ud_id === targetUserDataUdId
      );

      if (!targetUserData?.ud_id) {
        return Promise.reject("No id provided");
      }

      // check to see if the target user data id is connected to any people roles
      const peopleRole = state.peopleRoles.find(
        (pr) => pr.role_target_user_data_ud_id === targetUserData.ud_id
      );

      if (peopleRole) {
        console.info(
          "User Data connection has an id; thus it cannot be deleted."
        );

        return;
      }
      dispatch({
        type: "SET_TARGET_USER_DATAS",
        payload: state.targetUserDatas.filter(
          (ud) => ud.ud_id !== targetUserData.ud_id
        ),
      });
    },
    [state.targetUserDatas, state.peopleRoles]
  );

  const handlePeopleRolesFilesDrop = useCallback(
    (newFiles: File[]): void => {
      dispatch({
        type: "SET_PEOPLE_ROLES_FILES_TO_UPLOAD",
        payload: [...state.peopleRolesFilestoUpload, ...newFiles],
      });
    },
    [state.peopleRolesFilestoUpload]
  );

  const handlePeopleRolesFileRemove = useCallback(
    (file: File): void => {
      const updatedFiles = state.peopleRolesFilestoUpload.filter(
        (_file) => _file.path !== file.path
      );
      dispatch({
        type: "SET_PEOPLE_ROLES_FILES_TO_UPLOAD",
        payload: updatedFiles,
      });
    },
    [state.peopleRolesFilestoUpload]
  );

  const handlePeopleRolesFilesRemoveAll = useCallback((): void => {
    dispatch({ type: "SET_PEOPLE_ROLES_FILES_TO_UPLOAD", payload: [] });
  }, []);

  const handleUploadPeopleRolesFiles = useCallback(
    async (ud_id: string | null, peopleRoleId: string) => {
      if (!ud_id || !peopleRoleId) {
        console.error(
          `Missing required parameters ud_id: ${ud_id} peopleRoleId: ${peopleRoleId}`
        );
        return Promise.reject("Missing required parameters");
      }
      dispatch({ type: "SET_PEOPLE_ROLES_SAVING_NEW", payload: true });

      const files = state.peopleRolesFilestoUpload;
      const totalFiles = files.length;
      let successCount = 0;
      let failCount = 0;
      for (let index = 0; index < totalFiles; index++) {
        const currentFile = files[index];

        try {
          const res = await uploadDocument(
            {
              ud_id: ud_id,
              entity: "people-roles",
              entity_id: peopleRoleId,
            },
            currentFile
          );
          if (res) {
            successCount++;

            // update state
            dispatch({
              type: "ADD_OR_UPDATE_PEOPLE_ROLES",
              payload: res,
            });

            const updatedFiles = state.peopleRolesFilestoUpload.filter(
              (_file) => _file.path !== currentFile.path
            );
            dispatch({
              type: "SET_PEOPLE_ROLES_FILES_TO_UPLOAD",
              payload: updatedFiles,
            });

            const successMessage = `${successCount} of ${totalFiles} files uploaded successfully`;
            toast.success(successMessage);
          } else {
            failCount++;
            const errorMessage = `${failCount} of ${totalFiles} files failed to upload`;
            // Call your toast function here, e.g:
            toast.error(errorMessage);
            slackHook(
              `Document Error: Failed to upload document for entity_id: ${peopleRoleId} and document_id: ${currentFile.path}`
            );
          }
        } catch (error) {
          failCount++;
          const errorMessage = `${failCount} of ${totalFiles} files failed to upload`;
          // Call your toast function here, e.g:
          toast.error(errorMessage);

          console.error(errorMessage);
          slackHook(
            `Document Error: Failed to upload document for entity_id: ${peopleRoleId} and document_id: ${currentFile.path} with message "${error}"`
          );
        } finally {
          dispatch({ type: "SET_PEOPLE_ROLES_SAVING_NEW", payload: false });
        }
      }
    },
    [uploadDocument, state.peopleRolesFilestoUpload]
  );

  const handleDownloadPeopleRolesDocument = useCallback(
    async (peopleRoleId: string, document_id: string | null) => {
      const ud_id = state.currentViewingUserData?.ud_id || userData?.ud_id;
      if (!document_id || !peopleRoleId || !ud_id) {
        return Promise.reject("Missing required parameters");
      }
      toast.success("Downloading file...");

      const params: any = {
        document_id: document_id,
        entity: "people-roles",
        entity_id: peopleRoleId,
        ud_id: ud_id,
      };

      downloadDocument(params)
        .then((res: any) => {
          if (res) {
            // download File from s3 res and get file name from mathcing document id in tax objects' documents array
            downloadFileFromS3(
              res,
              state.peopleRoles
                ?.find((p) => p.id === peopleRoleId)
                ?.documents?.find((d) => d.document_id === document_id)
                ?.document_name || "Snug_Download.error"
            );
          } else {
            slackHook(
              `Document Error: Failed get document with message "No response"`
            );
          }
        })
        .catch((err) => {
          toast.error("Failed to download file");
          slackHook(
            `Document Error: Failed get document with message "${err}"`
          );
        });
    },
    [
      downloadDocument,
      state.peopleRoles,
      state.currentViewingUserData?.ud_id,
      userData?.ud_id,
    ]
  );

  const handleDeleteDocumentForPeopleRole = useCallback(
    async (peopleRoleId: string, document_id: string | null): Promise<void> => {
      const ud_id = state.currentViewingUserData?.ud_id || userData?.ud_id;

      if (!document_id || !peopleRoleId || !ud_id) {
        return Promise.reject("Missing required parameters");
      }
      toast.success("Removing the requested document.");
      try {
        dispatch({ type: "SET_PEOPLE_ROLES_SAVING_NEW", payload: true });

        await deleteDocumentById({
          ud_id: ud_id,
          entity: "people-roles",
          entity_id: peopleRoleId,
          document_id: document_id,
        });
        if (isMounted()) {
          // remove the document object formm the document array in the tax object
          const updatedPeopleRoles = state.peopleRoles.map((p) => {
            if (p.id === peopleRoleId) {
              return {
                ...p,
                documents: p.documents
                  ? p.documents.filter((d) => d.document_id !== document_id)
                  : [],
              };
            }
            return p;
          });
          dispatch({
            type: "SET_PEOPLE_ROLES",
            payload: updatedPeopleRoles,
          });
        }
      } catch (err) {
        console.error(err);
        toast.error("Failed to delete document");
        throw err;
      } finally {
        if (isMounted()) {
          dispatch({ type: "SET_PEOPLE_ROLES_SAVING_NEW", payload: false });
        }
      }
    },
    [
      isMounted,
      deleteDocumentById,
      state.peopleRoles,
      state.currentViewingUserData?.ud_id,
      userData?.ud_id,
    ]
  );

  const handleDeletePeopleRoles = useCallback(
    async (ud_id: string | null, id: string | null): Promise<void> => {
      if (!id || !ud_id) {
        return Promise.reject("No id provided");
      }
      try {
        // get people role by id, and get the people object from it.
        const peopleRole = state.peopleRoles.find((pr) => pr.id === id);
        if (!peopleRole) {
          throw new Error("People role not found");
        }
        dispatch({ type: "SET_PEOPLE_ROLES_SAVING_NEW", payload: true });
        handleDeleteTargetUserData(
          peopleRole.role_target_user_data_ud_id || ""
        );
        const params = {
          ud_id: ud_id,
          entity: "people-roles",
          entity_id: id,
        };
        const res = (await deleteById(params)) as {
          data: FirstDegreePeopleRole[];
        };
        if (isMounted()) {
          if (rolesWithHierarchy.includes(peopleRole.role || "")) {
            // all three of thre hiearchy roles of the same "role" are in the response and all need to be replaced in state. ie, if a parent role of "EXECUTOR", which is in the rolesWithHiearchy array, is deleted, all children roles of "EXECUTOR" need to be deleted as well.
            // and replaced with "res" which is the response from the deleteById function.
            const allRelatedRolesRemoved = state.peopleRoles.filter(
              (pr) => pr.role !== peopleRole.role
            );
            dispatch({
              type: "SET_PEOPLE_ROLES",
              payload: [...allRelatedRolesRemoved, ...res.data],
            });
          } else {
            const updatedPeopleRoles = state.peopleRoles.filter(
              (p) => p.id !== id
            );
            dispatch({ type: "SET_PEOPLE_ROLES", payload: updatedPeopleRoles });
          }
        }
      } catch (err) {
        console.error(err);
        toast.error("Failed to delete peopleRoles");
        throw err;
      } finally {
        if (isMounted()) {
          dispatch({ type: "SET_PEOPLE_ROLES_SAVING_NEW", payload: false });
        }
      }
    },
    [isMounted, state.peopleRoles, deleteById, handleDeleteTargetUserData]
  );

  const handleGetPeopleRoles = useCallback(
    async (ud_id: string | null, signal: AbortSignal | null) => {
      if (!ud_id) {
        return;
      }

      const params = {
        ud_id: ud_id,
        entity: "people-roles",
      };
      try {
        dispatch({ type: "SET_PEOPLE_ROLES_LOADING", payload: true });
        const response = await genericGetData(
          params,
          {
            expand: "role_target_user_data",
          },
          signal ? signal : undefined
        );
        if (isMounted()) {
          // get all unique target user data ids from the people roles and remove duplicates, nulls, and defineds and set into state
          const targetUserDatas = response
            .map((pr) => pr.role_target_user_data)
            .filter((ud) => ud !== null && ud !== undefined) as UserDataObj[];
          //now, the target user datas array will have duplictes, remove them to create a lisst of unqiue target user datas. can use ud_id as a unique identifier.
          const uniqueTargetUserDatas = targetUserDatas.filter(
            (ud, index, self) =>
              index ===
              self.findIndex((t) => t.ud_id === ud.ud_id || t === null)
          );
          dispatch({
            type: "SET_TARGET_USER_DATAS",
            payload: uniqueTargetUserDatas,
          });

          // remove target user data from all people roles and set into state
          const people_role_without_target_user_data = response.map((pr) => {
            const newy = { ...pr };
            delete newy.role_target_user_data;
            newy.role_target_user_data_ud_id = pr.role_target_user_data?.ud_id;
            return newy;
          });
          dispatch({
            type: "SET_PEOPLE_ROLES",
            payload: people_role_without_target_user_data,
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted()) {
          dispatch({ type: "SET_PEOPLE_ROLES_LOADING", payload: false });
        }
      }
    },
    [isMounted, genericGetData]
  );

  const handleRefreshPeopleRoles = useCallback(
    // this is just a wrapper for handleGetPeopleRoles function, calls that function with the current user data id as the parameter
    async () => {
      await handleGetPeopleRoles(state.currentViewingUserData?.ud_id || userData?.ud_id || null, null);
    },
    [handleGetPeopleRoles, userData?.ud_id, state.currentViewingUserData?.ud_id]
  );

  // this is the funcrtion that should die
  const handleInviteFromVerifyDialog = useCallback(
    async (targetUserData: UserDataObj | null) => {
      if (!targetUserData || !userData?.ud_id || !targetUserData.ud_id) {
        return;
      }

      try {
        dispatch({ type: "SET_TARGET_USER_DATA_SAVING", payload: true });

        // update user data
        await handleAddOrEditTargetUserData(
          targetUserData.ud_id,
          targetUserData
        );

        const params = {
          ud_id: userData?.ud_id || "",
          entity: "user-data",
          entity_id: targetUserData.ud_id || "",
        };
        await sendUserDataInviteFromVerify(params, {});
      } catch (err) {
        console.error(err);
        toast.error("Failed to send invite");
        throw err;
      } finally {
        dispatch({ type: "SET_TARGET_USER_DATA_SAVING", payload: false });
      }
    },
    [
      sendUserDataInviteFromVerify,
      handleAddOrEditTargetUserData,
      userData?.ud_id,
    ]
  );

  const handleAddOrUpdateAuthorizationRoles = useCallback(
    async (
      peopleRole: FirstDegreePeopleRole,
      targetUserData: UserDataObj | null,
      invite: boolean
    ): Promise<FirstDegreePeopleRole | null> => {
      if (
        !userData?.ud_id ||
        !peopleRole.role_target_user_data ||
        !authorizationRolesArray.includes(peopleRole.role || "_") ||
        !targetUserData?.ud_id
      ) {
        return Promise.reject("Parameters missing");
      }

      try {
        dispatch({ type: "SET_PEOPLE_ROLES_SAVING_NEW", payload: true });
        let res: FirstDegreePeopleRole | null;

        const params = {
          ud_id: userData?.ud_id, //use owners ud_id cause this funciton is only available owner on frontend;
          entity: "target-people-roles",
        };
        res = await createPeopleRole(params, {
          ...peopleRole,
          role_target_user_data_ud_id: userData?.ud_id,
          role_target_user_data: null,
          ud_id: targetUserData?.ud_id,
        });

        if (invite) {
          await sendUserDataInviteFromVerify(
            {
              ud_id: userData?.ud_id,
              entity: "user-data",
              entity_id: targetUserData?.ud_id,
            },
            {}
          );
        }

        if (res) {
          dispatch({
            type: "ADD_OR_UPDATE_TARGET_ROLES",
            payload: res,
          });
          return res;
        }
        return null;
      } catch (err) {
        if (err.response && err.response.status === 400) {
          if (err.response.data && err.response.data.details) {
            toast.error(err.response.data.details, { duration: 10000 });
          } else {
            toast.error(
              "Something went wrong! Check that your person is not a duplicate of another person in your dropdown and ensure the person being selected is over 18.",
              { duration: 10000 }
            );
          }
        } else {
          toast.error("Error adding your data! Please try again.");
        }
        throw err;
      } finally {
        dispatch({ type: "SET_PEOPLE_ROLES_SAVING_NEW", payload: false });
      }
    },
    [userData?.ud_id, createPeopleRole, sendUserDataInviteFromVerify]
  );

  const handleSendShareEmail = useCallback(
    async (ud_id: string) => {
      if (!userData?.ud_id || !ud_id) {
        return;
      }

      const params = {
        ud_id: userData?.ud_id,
        entity: "user-data",
        entity_id: ud_id,
      };
      try {
        await sendUserDataShareFromVerify(params, {});
      } catch (err) {
        console.error(err);
        toast.error("Failed to send email");
      }
    },
    [sendUserDataShareFromVerify, userData?.ud_id]
  );

  const handleAddOrUpdatePeopleRolesAndUserData = useCallback(
    async (
      ud_id: string | null,
      peopleRole: FirstDegreePeopleRole
    ): Promise<FirstDegreePeopleRole | null> => {
      if (
        !ud_id ||
        (!peopleRole.role_target_user_data &&
          !peopleRole.role_target_user_data_ud_id)
      ) {
        console.error("No user data id provided");
        return Promise.reject("No user data id provided");
      }

      try {
        dispatch({ type: "SET_PEOPLE_ROLES_SAVING_NEW", payload: true });
        let targetUserData: UserDataObj | null =
          peopleRole.role_target_user_data || null;
        let res: FirstDegreePeopleRole | null;

        if (peopleRole.role_target_user_data) {
          if (peopleRole.role_target_user_data?.ud_id === userData?.ud_id) {
            //use update owner
            targetUserData = await handleAddOrEditOwnerUserData({
              ...peopleRole.role_target_user_data,
            });
          } else {
            targetUserData = await handleAddOrEditRolesTargetUserData(
              ud_id,
              peopleRole.role_target_user_data || null
            );
          }

          if (targetUserData) {
            peopleRole.role_target_user_data_ud_id =
              targetUserData.ud_id || peopleRole.role_target_user_data_ud_id;
            delete peopleRole.role_target_user_data; //keep this clean.

            // set targetUserData into targetUserDatas state, replacing it if it exists or adding it if it doesn't
            dispatch({
              type: "ADD_OR_UPDATE_TARGET_USER_DATA",
              payload: targetUserData,
            });
          } else if (peopleRole.role_target_user_data) {
            delete peopleRole.role_target_user_data;
          } else {
            //if not target user data we're fucked here. throw error and report to frontend.
            throw new Error("User data not returned.");
          }
        }

        if (peopleRole.id) {
          const params = {
            ud_id: ud_id,
            entity: "people-roles",
            entity_id: peopleRole.id,
          };
          // remove field role_target_user_data
          res = await updatePeopleRole(params, {
            ...peopleRole,
            role_target_user_data_ud_id: targetUserData?.ud_id,
          });
          if (estatePlanningRolesArray.includes(res?.role || "_")) {
            const estateDocumentId = res.estate_document_id;
            const roleTargetUserDataId = res.role_target_user_data_ud_id;
            const personNameForRole = res.person_name_for_role;

            const peopleRolesToUpdate = state.peopleRoles.filter(
              (pr) =>
                pr.estate_document_id === estateDocumentId &&
                pr.role_target_user_data_ud_id === roleTargetUserDataId &&
                estatePlanningRolesArray.includes(pr.role || "_")
            );

            const updatedPeopleRoles = peopleRolesToUpdate.map((pr) => ({
              ...pr,
              person_name_for_role: personNameForRole,
            }));

            // now set people roles state, replacing the updated people roles
            dispatch({
              type: "SET_PEOPLE_ROLES",
              payload: state.peopleRoles.map((pr) => {
                const index = updatedPeopleRoles.findIndex(
                  (upr) => upr.id === pr.id
                );
                if (index > -1) {
                  return updatedPeopleRoles[index];
                }
                return pr;
              }),
            });
          }
        } else if (!peopleRole.id) {
          const params = {
            ud_id: ud_id,
            entity: "people-roles",
          };
          res = await createPeopleRole(params, {
            ...peopleRole,
            role_target_user_data_ud_id: targetUserData?.ud_id,
          });
        } else {
          res = null;
        }

        if (res?.id) {
          dispatch({
            type: "ADD_OR_UPDATE_PEOPLE_ROLES",
            payload: { ...res, role_target_user_data: null },
          });
          return res;
        }

        // weird workaround for temporary advisor client creation
        if (res) {
          return res;
        } else {
          return peopleRole;
        }
      } catch (err) {
        if (err.response && err.response.status === 400) {
          if (err.response.data && err.response.data.details) {
            toast.error(err.response.data.details, { duration: 10000 });
          } else {
            toast.error(
              "Something went wrong! Check that your person is not a duplicate of another person in your dropdown and ensure the person being selected is over 18.",
              { duration: 10000 }
            );
          }
        } else {
          toast.error("Error adding your data! Please try again.");
        }
        throw err;
      } finally {
        dispatch({ type: "SET_PEOPLE_ROLES_SAVING_NEW", payload: false });
      }
    },
    [
      createPeopleRole,
      updatePeopleRole,
      handleAddOrEditRolesTargetUserData,
      state.peopleRoles,
      handleAddOrEditOwnerUserData,
      userData?.ud_id,
    ]
  );

  const handleAddOrUpdatePeopleRoleSingleCall = useCallback(
    async (
      ud_id: string | null,
      peopleRole: FirstDegreePeopleRole
    ): Promise<void> => {
      if (
        !ud_id ||
        (!peopleRole.role_target_user_data &&
          !peopleRole.role_target_user_data_ud_id)
      ) {
        console.error("No user data id provided");
        return Promise.reject("No user data id provided");
      }

      try {
        dispatch({ type: "SET_PEOPLE_ROLES_SAVING_NEW", payload: true });
        let res: FirstDegreePeopleRole | null;

        if (peopleRole.id) {
          const params = {
            ud_id: ud_id,
            entity: "people-roles",
            entity_id: peopleRole.id,
          };
          // remove field role_target_user_data
          res = await updatePeopleRole(params, {
            ...peopleRole,
            role_target_user_data_ud_id:
              peopleRole?.role_target_user_data_ud_id ||
              peopleRole?.role_target_user_data?.ud_id,
          });
          if (estatePlanningRolesArray.includes(res?.role || "_")) {
            const estateDocumentId = res.estate_document_id;
            const roleTargetUserDataId = res.role_target_user_data_ud_id;
            const personNameForRole = res.person_name_for_role;

            const peopleRolesToUpdate = state.peopleRoles.filter(
              (pr) =>
                pr.estate_document_id === estateDocumentId &&
                pr.role_target_user_data_ud_id === roleTargetUserDataId &&
                estatePlanningRolesArray.includes(pr.role || "_")
            );

            const updatedPeopleRoles = peopleRolesToUpdate.map((pr) => ({
              ...pr,
              person_name_for_role: personNameForRole,
            }));

            // now set people roles state, replacing the updated people roles
            dispatch({
              type: "SET_PEOPLE_ROLES",
              payload: state.peopleRoles.map((pr) => {
                const index = updatedPeopleRoles.findIndex(
                  (upr) => upr.id === pr.id
                );
                if (index > -1) {
                  return updatedPeopleRoles[index];
                }
                return pr;
              }),
            });
          }
        } else {
          const params = {
            ud_id: ud_id,
            entity: "people-roles",
          };
          res = await createPeopleRole(params, {
            ...peopleRole,
            role_target_user_data_ud_id:
              peopleRole?.role_target_user_data_ud_id ||
              peopleRole?.role_target_user_data?.ud_id,
          });
        }

        dispatch({
          type: "ADD_OR_UPDATE_PEOPLE_ROLES",
          payload: { ...res, role_target_user_data: null },
        });
      } catch (err) {
        if (err.response && err.response.status === 400) {
          if (err.response.data && err.response.data.details) {
            toast.error(err.response.data.details, { duration: 10000 });
          } else {
            toast.error(
              "Something went wrong! Check that your person is not a duplicate of another person in your dropdown and ensure the person being selected is over 18.",
              { duration: 10000 }
            );
          }
        } else {
          toast.error("Error adding your data! Please try again.");
        }
        throw err;
      } finally {
        dispatch({ type: "SET_PEOPLE_ROLES_SAVING_NEW", payload: false });
      }
    },
    [createPeopleRole, updatePeopleRole, state.peopleRoles]
  );

  useEffect(() => {
    if (userData?.ud_id) {
      handleGetTargetAuthRoles(userData?.ud_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.ud_id]);

  useEffect(() => {
    if (
      sessionStorage.getItem("s_ac") &&
      userData?.ud_id &&
      state.targetAuthRoles.length > 0
    ) {
      console.log('setting auth context')
      handleSetAuthorizationContext(
        sessionStorage.getItem("s_ac") || "",
        state.targetAuthRoles
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.ud_id, state.targetAuthRoles]);

  useEffect(() => {
    const abortController = new AbortController();
    if (state.currentViewingUserData?.ud_id || userData?.ud_id) {
      handleGetPeopleRoles(
        state.currentViewingUserData?.ud_id || userData?.ud_id || null,
        abortController.signal
      );
      handleGetClientRole(
        state.currentViewingUserData?.ud_id || userData?.ud_id || ""
      );
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.currentViewingUserData,
    state.currentViewingUserData?.ud_id,
    userData?.ud_id,
  ]);

  const isPro =
    state.clientRole?.user_data?.professional_group_role_user_data
      ?.professional_group.professional_payment_tier === "PRO";
  const isWhiteLabel =
    state.clientRole?.user_data?.professional_group_role_user_data
      ?.professional_group.professional_payment_tier === "WHITE_LABEL" ||
    state.clientRole?.user_data?.professional_group_role_user_data
      ?.professional_group.professional_payment_tier === "COBRANDING";

  return (
    <UserDataContext.Provider
      value={{
        ownerUserData: userData,
        userData: state.currentViewingUserData || userData,
        targetUserDatas: state.targetUserDatas,
        userDataInitialLoading,
        userDataSaving,
        assumedAccountRole: state.assumedAccountRole,
        peopleRoles: state.peopleRoles,
        peopleRolesLoading: state.peopleRolesLoading,
        peopleRolesSavingNew: state.peopleRolesSavingNew,
        peopleRolesFilestoUpload: state.peopleRolesFilestoUpload,
        loadingViewingUserData: state.loadingViewingUserData,
        targetAuthRoles: state.targetAuthRoles,
        targetAuthRolesLoading: state.targetAuthRolesLoading,
        targetAuthRolesSavingNew: state.targetAuthRolesSavingNew,
        ownerUserDataInitialLoading: userDataInitialLoading,
        ownerUserDataSaving: userDataSaving,
        clientRole: state.clientRole,
        clientRoleLoading: state.clientRoleLoading,
        isPro: isPro,
        isWhiteLabel: isWhiteLabel,
        handleRefreshPeopleRoles,
        handleAddOrEditOwnerUserData,
        handleAddOrUpdatePeopleRoleSingleCall,
        handleAddOrUpdatePeopleRolesAndUserData,
        handleDeletePeopleRoles,
        handleDeleteDocumentForPeopleRole,
        handleDownloadPeopleRolesDocument,
        handlePeopleRolesFileRemove,
        handlePeopleRolesFilesDrop,
        handlePeopleRolesFilesRemoveAll,
        handleUploadPeopleRolesFiles,
        handleSetAuthorizationContext,
        handleInviteFromVerifyDialog,
        handleGetTargetAuthRoles,
        handleAddOrUpdateAuthorizationRoles,
        handleAddOrEditTargetUserData,
        handleSendShareEmail,
        handleAcceptDomainLeadRequest,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export default function useUserDataProvider() {
  return useContext(UserDataContext);
}
